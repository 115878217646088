import { Injectable } from '@angular/core';
import { HttpError } from '@microsoft/signalr';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import { CityPlanInvoiceListResponse } from '../interfaces/Admin/Invoice/CityPlanInvoiceListResponse';
import { EnterprisePlanInvoiceListResponse } from '../interfaces/Admin/Invoice/EnterprisePlanInvoiceListResponse';
import { ExtendedEnterprisePlanInvoiceListResponse } from '../interfaces/Admin/Invoice/ExtendedEnterprisePlanInvoiceListResponse';
import { InvoiceDetailsByIdResposne } from '../interfaces/Admin/Invoice/InvoiceDetailsByIdResponse';
import { InvoiceListRequest } from '../interfaces/Admin/Invoice/InvoiceListRequest';
import {
  OndemandInvoiceListRequest,
  OndemandTimesheetPreviewRequest,
} from '../interfaces/Admin/Invoice/OndemandInvoiceListRequest';
import { OndemandPlanInvoiceListResponse } from '../interfaces/Admin/Invoice/OndemandPlanInvoiceListResponse';
import { PaymentMethodListResponse } from '../interfaces/Admin/Invoice/PaymentMethodListResponse';
import { SendMultipleInvoicesByPlanTypeRequest } from '../interfaces/Admin/Invoice/SendMultipleInvoicesByPlanTypeRequest';
import { InvoiceUpdateRequest } from '../interfaces/Admin/Invoice/update-invoice-request';
import { TimeEntryType, TimeEntryTypeResponse } from '../interfaces/Admin/ManualRequest/TimeEntryTypeResponse';
import { SuccessResponse } from '../interfaces/Admin/SuccessResponse';
import { ClientInvoiceDataSource } from '../interfaces/Client/ClientDetails';
import { ApiService } from './api.service';

interface CityPlan {
  id: number;
  servicePlanName: string;
}
@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private servicePlanSubject = new BehaviorSubject<CityPlan[]>([]);
  servicePlanObservable: Observable<CityPlan[]> = this.servicePlanSubject.asObservable();

  private clientInvoicesSubject = new BehaviorSubject<ClientInvoiceDataSource | null>(null);
  clientInvoicesObservable: Observable<ClientInvoiceDataSource | null> = this.clientInvoicesSubject.asObservable();

  constructor(private _apiService: ApiService) {}

  setServicePlanData(data: { id: number; servicePlanName: string }[]) {
    this.servicePlanSubject.next(data);
  }

  setClientInvoices(data: ClientInvoiceDataSource) {
    data && this.clientInvoicesSubject.next(data);
  }

  getServicePlanId(cityPlan: string) {
    let temp: CityPlan | undefined;
    this.servicePlanObservable.subscribe((res) => {
      temp = res.find((row) => row.servicePlanName === cityPlan);
    });
    if (temp) return temp.id;
    else return -1;
  }

  sendMultipleInvoices(req: SendMultipleInvoicesByPlanTypeRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.SendMultipleInvoices, { ...req });
  }

  getPaymentMethodList(): Observable<PaymentMethodListResponse> {
    return this._apiService.get<PaymentMethodListResponse>(APIUrls.GetPaymentmethodList);
  }

  sendInvoice(id: number): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(`${APIUrls.SendInvoice}/${id}`, {}).pipe(
      catchError((error: HttpError) => {
        return of({ status: false, message: error.message, result: false, statusCode: error.statusCode });
      }),
    );
  }

  submitInvoice(id: number): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(`${APIUrls.SubmitInvoice}/${id}`, {});
  }

  //city invoice
  getCityPlanInvoiceList(req: InvoiceListRequest): Observable<CityPlanInvoiceListResponse> {
    return this._apiService.post<CityPlanInvoiceListResponse>(APIUrls.GetCitytPlanInvoiceListByType, { ...req });
  }

  getInvoiceDetailsById(id: number): Observable<InvoiceDetailsByIdResposne> {
    return this._apiService.post<InvoiceDetailsByIdResposne>(`${APIUrls.GetInvoiceDetailsById}/${id}`, {});
  }

  updateInvoiceDetails(invoice: InvoiceUpdateRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.UpdateInvoiceDetails, invoice);
  }

  //enterprise
  getEnterprisePlanInvoiceList(req: InvoiceListRequest): Observable<EnterprisePlanInvoiceListResponse> {
    return this._apiService.post<EnterprisePlanInvoiceListResponse>(APIUrls.GetEnterprisePlanInvoiceListByType, {
      ...req,
    });
  }

  //extended enterprise
  getExtendedEnterprisePlanInvoiceList(req: InvoiceListRequest): Observable<ExtendedEnterprisePlanInvoiceListResponse> {
    return this._apiService.post<ExtendedEnterprisePlanInvoiceListResponse>(
      APIUrls.GetExtendedEnterprisePlanInvoceListByType,
      { ...req },
    );
  }

  //download Invoice
  getInvoicePdf(invoiceId: number) {
    return this._apiService.post(
      APIUrls.DownloadPdfForInvoiceAndTimesheetReport,
      { invoiceId },
      { responseType: 'arraybuffer' },
    );
  }

  //preview Invoice
  previewInvoicePdf(invoiceId: number) {
    return this._apiService.get(
      APIUrls.GetInvoicePreview,
      { planInvoiceId: invoiceId },
      { responseType: 'arraybuffer' },
    );
  }

  downloadInvoicePdf(invoiceId: number): Observable<Blob> {
    const options = { responseType: 'blob' as 'json' };
    return this._apiService
      .get<Blob>(APIUrls.GetInvoicePreview, { planInvoiceId: invoiceId }, options)
      .pipe(map((res) => new Blob([res], { type: 'application/pdf' })));
  }

  //ondemand
  getOndemandPlanInvoiceList(req: OndemandInvoiceListRequest): Observable<OndemandPlanInvoiceListResponse> {
    return this._apiService.post<OndemandPlanInvoiceListResponse>(APIUrls.GetOnDemandPlanInvoiceList, { ...req });
  }

  previewOndemandTimesheetPdf(req: OndemandTimesheetPreviewRequest) {
    return this._apiService.post(APIUrls.GetTimesheetPreview, { ...req }, { responseType: 'arraybuffer' });
  }
  downloadOndemandTimesheetPdf(req: OndemandTimesheetPreviewRequest) {
    return this._apiService.post(APIUrls.DownloadOnDemandTimesheet, { ...req }, { responseType: 'arraybuffer' });
  }

  resendOndemandTimesheet(req: OndemandTimesheetPreviewRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.ResendOnDemandTimesheet, { ...req });
  }

  private timeEntryTypes: TimeEntryType[] = [];

  getTimeEntryTypeList(): Observable<TimeEntryType[]> {
    // return this._apiService.post<TimeEntryTypeResponse>(APIUrls.GetTimeEntryTypes, {});
    if (this.timeEntryTypes.length) {
      return of(this.timeEntryTypes);
    }

    return this._apiService.get<TimeEntryTypeResponse>(APIUrls.GetInvoiceTimeEntryTypes, {}).pipe(
      catchError((error) => {
        console.error('Error loading Time Entry Types', error);
        return of({ result: [] });
      }),
      map((res) => {
        if (res.status && res.result) {
          this.timeEntryTypes = res.result;
        }
        return this.timeEntryTypes;
      }),
    );
  }
}
